<template>
  <rtb-card class="team-container">
    <v-layout row>
      <v-flex xs3>
        <rtb-select
          v-model="computedPlaythroughID"
          :options="unclaimedPlaythroughs"
          identity="id"
          option-text="label"
          class="ma-1"
          append-to-body
          size="sm"
          :disabled="!!remotePlaythroughID"
        >
          <template #help>
            <rtb-inline-help>playthrough</rtb-inline-help>
          </template>
        </rtb-select>
      </v-flex>
      <v-flex shrink>
        <v-spacer style="height: 8px"></v-spacer>
        <rtb-button
          v-if="!remotePlaythroughID"
          color="success"
          class="mt-3 mr-1"
          @click="savePlaythrough"
          size="sm"
          :disabled="saving"
          >Save</rtb-button
        >
        <rtb-button
          v-else
          color="danger"
          class="mt-3"
          @click="resetPlaythrough"
          size="sm"
          >Reset</rtb-button
        >
      </v-flex>
      <v-flex
        v-if="getPlaythrough(computedPlaythroughID)"
        class="team-container___breadcrumb-link"
      >
        <a
          :href="getPlaythrough(computedPlaythroughID)"
          target="_blank"
          class="game-info-tooltip__href"
        >
          {{ getPlaythrough(computedPlaythroughID) }}
        </a>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      class="team-container"
      :class="{ 'team-container--scroll': scroll }"
    >
      <v-flex
        column
        xs3
        v-for="(team, index) in game.teams || []"
        :key="`team-${index}`"
      >
        <TeamEdit
          :game="game"
          :team="team"
          :sisterTeams="sisterTeams"
          :needsUpdate="true"
          @update="updateLocalGame"
        />
      </v-flex>
    </v-layout>
  </rtb-card>
</template>

<script>
import { mapGetters } from "vuex"
import { db } from "@/firebase"
import TeamEdit from "./TeamEdit.vue"
import {
  RtbCard,
  RtbSelect,
  RtbInlineHelp,
  RtbTextInput,
  RtbButton
} from "@/components/ui"
import axios from "axios"

export default {
  name: "GameTeams",
  components: {
    RtbCard,
    RtbSelect,
    RtbInlineHelp,
    RtbTextInput,
    TeamEdit,
    RtbButton
  },
  props: {
    game: {
      required: true
    },
    teamsLoaded: {
      required: false,
      default: false
    },
    playthroughs: {
      default: () => []
    },
    scroll: {
      require: false,
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      saving: false,
      playthroughID: null,
      sisterTeams: [],
      reseting: false
    }
  },
  computed: {
    ...mapGetters("auth", ["clientID"]),
    ...mapGetters(["orgID"]),
    unclaimedPlaythroughs() {
      return this.playthroughs
        .filter(play => {
          if (play.claimedBy === this.game.id) return true
          return !play.claimed
        })
        .map(play => {
          return {
            ...play,
            label: play.playthroughName || play.id
          }
        })
    },
    remoteGame() {
      return this.$store.getters.game
    },
    savedPlaythrough() {},
    computedPlaythroughID: {
      get() {
        return this.remoteGame.playthroughID || this.playthroughID
      },
      set(id) {
        this.playthroughID = id
      }
    },
    getPlaythrough() {
      return computedPlaythroughID => {
        const playthrough = this.playthroughs.find(
          playthrough => playthrough.id === computedPlaythroughID
        )
        return playthrough?.breadcrumbUrl ?? null
      }
    },
    remotePlaythroughID() {
      return this.remoteGame?.playthroughID
    }
  },
  watch: {
    computedPlaythroughID: {
      async handler(id) {
        await this.getPlaythroughTeams(id)
      },
      immediate: true
    }
  },
  methods: {
    async savePlaythrough() {
      if (!this.playthroughID) return
      this.saving = true
      const updates = {
        [`org/${this.orgID}/games/${this.game.id}/playthroughID`]:
          this.playthroughID,
        [`client/${this.clientID}/playthrough/${this.playthroughID}/claimed`]: true,
        [`client/${this.clientID}/playthrough/${this.playthroughID}/claimedBy`]:
          this.game.id
      }
      await db.auxiliary().ref().update(updates)
      this.$emit("updateLocalGame", {
        playthroughID: this.playthroughID
      })
      this.saving = false
    },
    async getPlaythroughTeams(id) {
      if (!id) return
      const { data } = await axios.get(
        `${process.env.VUE_APP_BREADCRUMB_API}objects/teams?is_archived=false&order_by=-id&playthrough=${id}&range_size=50&range_start=0`
      )
      const { teams } = data
      console.log(teams)
      this.sisterTeams = teams || []
    },
    updateLocalGame(obj) {
      this.$emit("teamUpdate", obj)
    },
    async resetPlaythrough() {
      if (!this.remotePlaythroughID) return
      this.reseting = true
      const updates = {
        [`org/${this.orgID}/games/${this.game.id}/playthroughID`]: null,
        [`client/${this.clientID}/playthrough/${this.remotePlaythroughID}/claimed`]:
          null,
        [`client/${this.clientID}/playthrough/${this.remotePlaythroughID}/claimedBy`]:
          null
      }
      await db.auxiliary().ref().update(updates)
      this.localGame
      this.$emit("updateLocalGame", { playthroughID: null })
      this.reseting = false
    }
  }
}
</script>

<style lang="scss">
.team-container {
  &--scroll {
    height: 400px;
    overflow: auto;
  }
  &___breadcrumb-link {
    align-items: center;
    display: flex;
    margin-top: 17px;
    margin-left: 6px;
    a {
      color: $color-white;
    }
  }

  .rtb-select {
    .vs--single .vs__selected {
      color: black;
    }
  }
}
</style>
