<template>
  <div>
    <rtb-table />
    <v-data-table
      :headers="filteredHeaders"
      :items="data"
      :loading="loading"
      :pagination.sync="pagination"
      :search="search"
      class="elevation-1 rtb-table"
      itemKey="Email"
    >
      <v-progress-linear
        v-slot:progress
        color="blue"
        indeterminate
      ></v-progress-linear>

      <template #items="props">
        <tr>
          <td>
            <rtb-button
              class="mr-1"
              variant="icon"
              color="danger"
              :disabled="loading"
              aria-label="Delete"
              @click="onDelete(props.item)"
            >
              <svg-icon name="trash-regular" />
            </rtb-button>
          </td>
          <td>
            <rtb-text-input v-model="props.item.first_name" />
          </td>
          <td>
            <rtb-text-input v-model="props.item.last_name" />
          </td>
          <td>
            <rtb-text-input v-model="props.item.email" />
          </td>
          <td v-if="!game">
            <rtb-select
              v-model="props.item.gameID"
              :options="gameList"
              @change="onChangeGame"
              append-to-body
              identity="theKey"
              option-text="name"
              label="Room"
              placeholder=""
              hide-label
              class="w-64"
            >
            </rtb-select>
          </td>
          <td>
            <rtb-select
              v-if="teams"
              v-model="props.item.teamID"
              append-to-body
              :options="getTeamsByGameID(props.item.gameID)"
              identity="id"
              option-text="name"
              label="Team"
              placeholder=""
              hide-label
              class="w-32"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { RtbTable, RtbTextInput, RtbSelect, RtbButton } from "@/components/ui"

export default {
  name: "MatchEmailsTable",
  props: {
    teams: {
      type: Object
    },
    games: {
      type: Array
    },
    data: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    },
    game: {
      type: Object,
      default: null
    },
    search: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "id"
        },
        {
          text: "First Name",
          value: "first_name"
        },
        {
          text: "Last Name",
          value: "last_name"
        },
        {
          text: "Email",
          value: "email"
        },
        {
          text: "Room",
          value: "gameID"
        },
        {
          text: "Team",
          value: "teamID"
        }
      ],
      pagination: {}
    }
  },
  components: {
    RtbSelect,
    RtbTable,
    RtbTextInput,
    RtbButton
  },
  methods: {
    onChangeGame(item) {
      item.teamID = null
    },
    getTeamsByGameID(gameID) {
      return this.teams[gameID] || []
    },
    onDelete(item) {
      this.data.splice(this.data.indexOf(item), 1)
    }
  },
  computed: {
    gameList() {
      return this.games.map(({ theKey, externalName, name }) => ({
        theKey,
        name: externalName || name
      }))
    },
    filteredHeaders() {
      return this.headers.filter(
        ({ value }) => !this.game || value !== "gameID"
      )
    }
  }
}
</script>

<style scoped></style>
