



























































































































import Vue, { PropType } from "vue"
import { cloneDeep } from "lodash"
import { ValidationObserver } from "vee-validate"
import type { WithRefs } from "vue-typed-refs"

import rules from "@/config/rules"

import {
  RtbRow,
  RtbCol,
  RtbButton,
  RtbCard,
  RtbCardBody,
  RtbCardActions,
  RtbTextInput,
  RtbCheckbox,
  RtbTabs,
  RtbTab,
  RtbSpinner,
  RtbInlineHelp
} from "@/components/ui"
import ImageUploaderNext from "@/components/ImageUploader.vue"

import type { Theme } from "../types"

import { ThemeFactory } from "../entities/ThemeFactory"

import { MODULE_NAME as THEME_MODULE_NAME } from "../store"
import ThemeModuleGetterTypes from "../store/getter-types"
import ThemeModuleActionTypes from "../store/action-types"

import StylesEditor from "./StylesEditor.vue"
import TagSelect from "./TagSelect.vue"
import { DEFAULT_THEME_STYLES } from "../constants"

export type Refs = {
  observer: InstanceType<typeof ValidationObserver>
}

enum Tab {
  General,
  Images
}

export default (Vue as WithRefs<Refs>).extend({
  name: "ThemeCustomizer",
  components: {
    ValidationObserver,
    RtbRow,
    RtbCol,
    RtbButton,
    RtbCard,
    RtbCardBody,
    RtbCardActions,
    RtbTextInput,
    RtbCheckbox,
    RtbTabs,
    RtbTab,
    RtbSpinner,
    RtbInlineHelp,
    StylesEditor,
    TagSelect,
    ImageUploaderNext
  },
  props: {
    theme: {
      type: Object as PropType<Theme>,
      required: true
    }
  },
  data() {
    return {
      localTheme: null as Theme | null,
      tab: Tab.General,
      loading: false
    }
  },
  computed: {
    orgID(): string {
      return this.$store.getters.orgID
    }
  },
  watch: {
    theme: {
      handler(value) {
        this.localTheme = cloneDeep(value)
        this.localTheme.styles = {
          ...DEFAULT_THEME_STYLES,
          ...this.localTheme.styles
        }
      },
      immediate: true
    }
  },
  methods: {
    save({ copy }: { copy: boolean } = { copy: false }) {
      this.loading = true
      this.$refs.observer.validate().then(valid => {
        if (valid) {
          const theme = this.localTheme

          this.$store
            .dispatch(
              `${THEME_MODULE_NAME}/${ThemeModuleActionTypes.UPDATE_ORG_THEME}`,
              {
                orgID: this.orgID,
                themeID: copy ? undefined : theme.id,
                value: theme
              }
            )
            .then(theme => {
              this.$emit("update", theme)
              this.localTheme = null
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    deleteTheme() {
      const themeID = this.localTheme?.id

      if (themeID) {
        this.loading = true
        this.$store
          .dispatch(
            `${THEME_MODULE_NAME}/${ThemeModuleActionTypes.UPDATE_ORG_THEME}`,
            {
              orgID: this.orgID,
              themeID,
              value: this.localTheme
            }
          )
          .then(() => {
            this.localTheme = null
          })
          .finally(() => {
            this.loading = false
            this.$emit("delete")
          })
      } else {
        this.localTheme = null
        this.$emit("delete")
      }
    },
    getRules() {
      return rules
    }
  }
})
