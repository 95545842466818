var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-card",
    { staticClass: "team-container" },
    [
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs3: "" } },
            [
              _c("rtb-select", {
                staticClass: "ma-1",
                attrs: {
                  options: _vm.unclaimedPlaythroughs,
                  identity: "id",
                  "option-text": "label",
                  "append-to-body": "",
                  size: "sm",
                  disabled: !!_vm.remotePlaythroughID,
                },
                scopedSlots: _vm._u([
                  {
                    key: "help",
                    fn: function () {
                      return [_c("rtb-inline-help", [_vm._v("playthrough")])]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.computedPlaythroughID,
                  callback: function ($$v) {
                    _vm.computedPlaythroughID = $$v
                  },
                  expression: "computedPlaythroughID",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _c("v-spacer", { staticStyle: { height: "8px" } }),
              !_vm.remotePlaythroughID
                ? _c(
                    "rtb-button",
                    {
                      staticClass: "mt-3 mr-1",
                      attrs: {
                        color: "success",
                        size: "sm",
                        disabled: _vm.saving,
                      },
                      on: { click: _vm.savePlaythrough },
                    },
                    [_vm._v("Save")]
                  )
                : _c(
                    "rtb-button",
                    {
                      staticClass: "mt-3",
                      attrs: { color: "danger", size: "sm" },
                      on: { click: _vm.resetPlaythrough },
                    },
                    [_vm._v("Reset")]
                  ),
            ],
            1
          ),
          _vm.getPlaythrough(_vm.computedPlaythroughID)
            ? _c(
                "v-flex",
                { staticClass: "team-container___breadcrumb-link" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "game-info-tooltip__href",
                      attrs: {
                        href: _vm.getPlaythrough(_vm.computedPlaythroughID),
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getPlaythrough(_vm.computedPlaythroughID)
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-layout",
        {
          staticClass: "team-container",
          class: { "team-container--scroll": _vm.scroll },
          attrs: { row: "", wrap: "" },
        },
        _vm._l(_vm.game.teams || [], function (team, index) {
          return _c(
            "v-flex",
            { key: "team-" + index, attrs: { column: "", xs3: "" } },
            [
              _c("TeamEdit", {
                attrs: {
                  game: _vm.game,
                  team: team,
                  sisterTeams: _vm.sisterTeams,
                  needsUpdate: true,
                },
                on: { update: _vm.updateLocalGame },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }