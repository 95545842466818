var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "team-conersation-edit" },
    [
      _c(
        "v-layout",
        [
          _c("rtb-text-input", {
            staticClass: "ma-1",
            attrs: { label: "Team Name", type: "text", size: "sm" },
            scopedSlots: _vm._u([
              {
                key: "help",
                fn: function () {
                  return [_c("rtb-inline-help", [_vm._v(" The Team Name ")])]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.teamCopy.name,
              callback: function ($$v) {
                _vm.$set(_vm.teamCopy, "name", $$v)
              },
              expression: "teamCopy.name",
            },
          }),
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c("rtb-select", {
            staticClass: "ma-1",
            attrs: {
              options: _vm.sisterTeams,
              identity: "id",
              label: "Sister Team Name",
              "option-text": "name",
              "append-to-body": "",
              size: "sm",
            },
            scopedSlots: _vm._u([
              {
                key: "help",
                fn: function () {
                  return [
                    _c("rtb-inline-help", [
                      _vm._v(
                        "When you want to add an additional field next to the first and last name"
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.teamCopy.sisterTeamID,
              callback: function ($$v) {
                _vm.$set(_vm.teamCopy, "sisterTeamID", $$v)
              },
              expression: "teamCopy.sisterTeamID",
            },
          }),
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c(
            "v-btn",
            {
              attrs: { small: "", disabled: !_vm.isDirty || _vm.isSaving },
              on: { click: _vm.onSaveTeam },
            },
            [_vm._v(" Save Changes")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }