<template>
  <rtb-row>
    <rtb-col v-for="(value, key) in value" :key="key" xs="3">
      <rtb-color-picker
        :value="value"
        :label="key"
        :rules="getRules().ThemeColor"
        @input="onInput(key, $event)"
      ></rtb-color-picker>
    </rtb-col>
  </rtb-row>
</template>

<script>
import Vue from "vue"
import { debounce } from "lodash"

import rules from "@/config/rules"

import { RtbRow, RtbCol, RtbColorPicker } from "@/components/ui"

export default Vue.extend({
  name: "StylesEditor",
  components: {
    RtbRow,
    RtbCol,
    RtbColorPicker
  },
  props: {
    value: {
      /** @type {import('vue').PropType<import('../types').Styles}> */
      type: Object,
      required: true
    }
  },
  created() {
    // this.onInput = debounce(this.onInput, 400)
  },
  methods: {
    /**
     * @param {string} key
     * @param {import('./types').ColorPickerInputEventPayload} value
     */
    onInput(key, value) {
      /** @type {import('./types').StylesEditorInputEventPayload} */
      const payload = { ...this.value, [key]: value }
      this.$emit("input", payload)
    },
    getRules() {
      return rules
    }
  }
})
</script>
