var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "no-click-animation": "",
        width: "1024px",
        "content-class": "overflow-auto",
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "ThemeCustomizer",
        _vm._g(
          _vm._b(
            {
              on: { update: _vm.closeModal, delete: _vm.closeModal },
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function () {
                    return [
                      _c(
                        "rtb-button",
                        {
                          attrs: { color: "dark" },
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v("Close")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            "ThemeCustomizer",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }