<template>
  <v-dialog
    v-model="visible"
    persistent
    no-click-animation
    width="1024px"
    content-class="overflow-auto"
  >
    <ThemeCustomizer
      v-bind="$attrs"
      v-on="$listeners"
      @update="closeModal"
      @delete="closeModal"
    >
      <template #actions>
        <rtb-button color="dark" @click="closeModal">Close</rtb-button>
      </template>
    </ThemeCustomizer>
  </v-dialog>
</template>

<script>
import Vue from "vue"
import mixins from "vue-typed-mixins"

import { ModalMixin } from "@/mixins/Modal"

import { RtbButton } from "@/components/ui"

import ThemeCustomizer from "./ThemeCustomizer.vue"

export default mixins(Vue.extend(ModalMixin)).extend({
  name: "ThemeCustomizerModal",
  inheritAttrs: false,
  components: {
    RtbButton,
    ThemeCustomizer
  }
})
</script>
