var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "validation-observer",
    { ref: "observer", attrs: { slim: "" } },
    [
      _vm.localTheme
        ? _c(
            "rtb-card",
            { staticClass: "theme-customizer" },
            [
              _c(
                "rtb-tabs",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "rtb-tab",
                    { key: "general", attrs: { title: "General" } },
                    [
                      _c(
                        "rtb-card-body",
                        [
                          _c(
                            "rtb-row",
                            [
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "Name",
                                      rules: _vm.getRules().ThemeName,
                                      disabled: _vm.loading,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "The name this theme will be refered to - make it specific"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1133998841
                                    ),
                                    model: {
                                      value: _vm.localTheme.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localTheme, "name", $$v)
                                      },
                                      expression: "localTheme.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "2" } },
                                [
                                  _c("TagSelect", {
                                    attrs: { disabled: _vm.loading },
                                    model: {
                                      value: _vm.localTheme.tag,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localTheme, "tag", $$v)
                                      },
                                      expression: "localTheme.tag",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "Tagline",
                                      rules: _vm.getRules().ThemeTagline,
                                      disabled: _vm.loading,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "Change the tagline on the login page"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2938878380
                                    ),
                                    model: {
                                      value: _vm.localTheme.meta.tagline,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localTheme.meta,
                                          "tagline",
                                          $$v
                                        )
                                      },
                                      expression: "localTheme.meta.tagline",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("StylesEditor", {
                            model: {
                              value: _vm.localTheme.styles,
                              callback: function ($$v) {
                                _vm.$set(_vm.localTheme, "styles", $$v)
                              },
                              expression: "localTheme.styles",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "mt-3" },
                            [
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: {
                                  label: "Hide Go Game",
                                  disabled: _vm.loading,
                                },
                                model: {
                                  value: _vm.localTheme.hideGoGame,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localTheme, "hideGoGame", $$v)
                                  },
                                  expression: "localTheme.hideGoGame",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "rtb-tab",
                    { key: "images", attrs: { title: "Images" } },
                    [
                      _c(
                        "rtb-card-body",
                        [
                          _c(
                            "rtb-row",
                            [
                              _c(
                                "rtb-col",
                                { attrs: { xs: "4" } },
                                [
                                  _c("image-uploader-next", {
                                    attrs: { label: "Login Logo" },
                                    model: {
                                      value: _vm.localTheme.images.logo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localTheme.images,
                                          "logo",
                                          $$v
                                        )
                                      },
                                      expression: "localTheme.images.logo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "4" } },
                                [
                                  _c("image-uploader-next", {
                                    attrs: { label: "Top Left Image" },
                                    model: {
                                      value: _vm.localTheme.images.banner,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localTheme.images,
                                          "banner",
                                          $$v
                                        )
                                      },
                                      expression: "localTheme.images.banner",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "4" } },
                                [
                                  _c("image-uploader-next", {
                                    attrs: { label: "Expo Custom Logo" },
                                    model: {
                                      value: _vm.localTheme.images.expoLogo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localTheme.images,
                                          "expoLogo",
                                          $$v
                                        )
                                      },
                                      expression: "localTheme.images.expoLogo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "4" } },
                                [
                                  _c("image-uploader-next", {
                                    attrs: { label: "Expo Custom Logo Large" },
                                    model: {
                                      value:
                                        _vm.localTheme.images.expoLogoLarge,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localTheme.images,
                                          "expoLogoLarge",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "localTheme.images.expoLogoLarge",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "rtb-row",
                            _vm._l(
                              _vm.localTheme.images.loginCarousel,
                              function (image, index) {
                                return _c(
                                  "rtb-col",
                                  { key: index, attrs: { xs: "4" } },
                                  [
                                    _c("image-uploader-next", {
                                      attrs: { label: "Login Page Image" },
                                      model: {
                                        value:
                                          _vm.localTheme.images.loginCarousel[
                                            index
                                          ],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.localTheme.images.loginCarousel,
                                            index,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "localTheme.images.loginCarousel[index]",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                          _c(
                            "rtb-row",
                            [
                              _c(
                                "rtb-col",
                                { attrs: { xs: "4" } },
                                [
                                  _c("image-uploader-next", {
                                    attrs: { label: "Background Image" },
                                    model: {
                                      value:
                                        _vm.localTheme.images.backgroundImage,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localTheme.images,
                                          "backgroundImage",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "localTheme.images.backgroundImage",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rtb-card-actions",
                [
                  _c("rtb-spinner", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "mr-2",
                  }),
                  _vm._t("actions"),
                  _c(
                    "rtb-button",
                    {
                      attrs: { color: "danger", disabled: _vm.loading },
                      on: { click: _vm.deleteTheme },
                    },
                    [_vm._v("Delete")]
                  ),
                  _c(
                    "rtb-button",
                    {
                      attrs: { color: "secondary", disabled: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.save({ copy: true })
                        },
                      },
                    },
                    [
                      _c("svg-icon", { attrs: { name: "copy-regular" } }),
                      _vm._v("Copy"),
                    ],
                    1
                  ),
                  _c(
                    "rtb-button",
                    {
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.save },
                    },
                    [
                      _c("svg-icon", { attrs: { name: "save-regular" } }),
                      _vm._v(" Save"),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }