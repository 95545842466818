var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-row",
    _vm._l(_vm.value, function (value, key) {
      return _c(
        "rtb-col",
        { key: key, attrs: { xs: "3" } },
        [
          _c("rtb-color-picker", {
            attrs: {
              value: value,
              label: key,
              rules: _vm.getRules().ThemeColor,
            },
            on: {
              input: function ($event) {
                return _vm.onInput(key, $event)
              },
            },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }