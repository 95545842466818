import instance from "./axios.service"

export default class ConversationService {
  static linkHybridTeams(reqObj) {
    const { orgID, gameID, teamID, sisterTeamID } = reqObj
    return instance({
      method: "post",
      url: `/game/${gameID}/team/${teamID}/sister-team/${sisterTeamID}`,
      headers: { "x-gogame-org": orgID }
    })
  }
}
