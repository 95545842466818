var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("rtb-table"),
      _c(
        "v-data-table",
        {
          staticClass: "elevation-1 rtb-table",
          attrs: {
            headers: _vm.filteredHeaders,
            items: _vm.data,
            loading: _vm.loading,
            pagination: _vm.pagination,
            search: _vm.search,
            itemKey: "Email",
          },
          on: {
            "update:pagination": function ($event) {
              _vm.pagination = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "items",
              fn: function (props) {
                return [
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c(
                          "rtb-button",
                          {
                            staticClass: "mr-1",
                            attrs: {
                              variant: "icon",
                              color: "danger",
                              disabled: _vm.loading,
                              "aria-label": "Delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onDelete(props.item)
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              attrs: { name: "trash-regular" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("rtb-text-input", {
                          model: {
                            value: props.item.first_name,
                            callback: function ($$v) {
                              _vm.$set(props.item, "first_name", $$v)
                            },
                            expression: "props.item.first_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("rtb-text-input", {
                          model: {
                            value: props.item.last_name,
                            callback: function ($$v) {
                              _vm.$set(props.item, "last_name", $$v)
                            },
                            expression: "props.item.last_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("rtb-text-input", {
                          model: {
                            value: props.item.email,
                            callback: function ($$v) {
                              _vm.$set(props.item, "email", $$v)
                            },
                            expression: "props.item.email",
                          },
                        }),
                      ],
                      1
                    ),
                    !_vm.game
                      ? _c(
                          "td",
                          [
                            _c("rtb-select", {
                              staticClass: "w-64",
                              attrs: {
                                options: _vm.gameList,
                                "append-to-body": "",
                                identity: "theKey",
                                "option-text": "name",
                                label: "Room",
                                placeholder: "",
                                "hide-label": "",
                              },
                              on: { change: _vm.onChangeGame },
                              model: {
                                value: props.item.gameID,
                                callback: function ($$v) {
                                  _vm.$set(props.item, "gameID", $$v)
                                },
                                expression: "props.item.gameID",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "td",
                      [
                        _vm.teams
                          ? _c("rtb-select", {
                              staticClass: "w-32",
                              attrs: {
                                "append-to-body": "",
                                options: _vm.getTeamsByGameID(
                                  props.item.gameID
                                ),
                                identity: "id",
                                "option-text": "name",
                                label: "Team",
                                placeholder: "",
                                "hide-label": "",
                              },
                              model: {
                                value: props.item.teamID,
                                callback: function ($$v) {
                                  _vm.$set(props.item, "teamID", $$v)
                                },
                                expression: "props.item.teamID",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c("v-progress-linear", {
            attrs: { color: "blue", indeterminate: "" },
            scopedSlots: _vm._u([
              {
                key: "progress",
                fn: function () {
                  return undefined
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }