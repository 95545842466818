<template>
  <div class="team-conersation-edit">
    <v-layout>
      <rtb-text-input
        label="Team Name"
        type="text"
        class="ma-1"
        v-model="teamCopy.name"
        size="sm"
      >
        <template #help>
          <rtb-inline-help> The Team Name </rtb-inline-help>
        </template>
      </rtb-text-input>
    </v-layout>
    <v-layout column>
      <rtb-select
        v-model="teamCopy.sisterTeamID"
        :options="sisterTeams"
        identity="id"
        label="Sister Team Name"
        option-text="name"
        class="ma-1"
        append-to-body
        size="sm"
        ><template #help
          ><rtb-inline-help
            >When you want to add an additional field next to the first and last
            name</rtb-inline-help
          ></template
        ></rtb-select
      >
    </v-layout>
    <v-layout>
      <v-btn small :disabled="!isDirty || isSaving" @click="onSaveTeam">
        Save Changes</v-btn
      >
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { db } from "@/firebase"
import ConversationService from "@/services/conversation.service"
import {
  RtbRow,
  RtbCol,
  RtbTextInput,
  RtbSelect,
  RtbInlineHelp
} from "@/components/ui"

export default {
  name: "TeamEdit",
  components: {
    RtbRow,
    RtbCol,
    RtbTextInput,
    RtbSelect,
    RtbInlineHelp
  },
  data() {
    return {
      teamCopy: {},
      isSaving: false
    }
  },
  props: {
    team: {
      required: true,
      type: Object
    },
    game: {},
    sisterTeams: {
      default: () => []
    },
    needsUpdate: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("auth", ["clientID"]),
    ...mapGetters(["orgID"]),
    isDirty() {
      const { name, sisterTeamID } = this.teamCopy
      return this.team.name !== name || this.team.sisterTeamID !== sisterTeamID
    }
  },
  watch: {
    team: {
      handler(value) {
        this.teamCopy = { ...value }
      },
      immediate: true
    }
  },
  methods: {
    async onSaveTeam() {
      this.isSaving = true
      const { id, name, sisterTeamID } = this.teamCopy
      const { id: gameID } = this.game
      const orgID = this.orgID

      try {
        if (this.team.sisterTeamID !== sisterTeamID) {
          await ConversationService.linkHybridTeams({
            orgID,
            gameID,
            teamID: id,
            sisterTeamID
          })
        }

        if (this.team.name !== name) {
          await db
            .auxiliary()
            .ref(`org/${orgID}/game/${gameID}/teams/${id}`)
            .update({ name })
        }
        if (this.needsUpdate)
          this.$emit("update", {
            gameID,
            update: { ...this.teamCopy, name, sisterTeamID }
          })
      } catch (e) {
        console.log(e)
      }

      this.isSaving = false
    }
  }
}
</script>

<style lang="scss">
.team-conersation-edit {
  background: #21212130;
  padding: 3px;
  margin: 1px;
  border-radius: 4px;
}
</style>
